var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" PAGAMENTOS "),_c('v-spacer'),_c('search',{on:{"applyFilters":_vm.applyFilters}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.pagamentos,"single-expand":true,"expanded":_vm.expanded,"item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.statusComissao",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-chip',{attrs:{"color":item.statusComissao == 'Pago' ? 'success' : 'warning'}},[_vm._v(" "+_vm._s(item.statusComissao)+" ")])],1)]}},{key:"item.totalComissaoAcerto",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("currency")(item.totalComissaoAcerto)))])]}},{key:"item.totalComissaoConsignado",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("currency")(item.totalComissaoConsignado)))])]}},{key:"item.totalComissaoCadastrosConsignados",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("currency")(item.totalComissaoCadastrosConsignados)))])]}},{key:"item.totalComissaoCatalogo",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("currency")(item.totalComissaoCatalogo)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.showPagamento && item.pagamentoPendente)?_c('v-icon',{attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.openPagamento(item)}}},[_vm._v(" mdi-cash-plus ")]):_vm._e(),(item.obs)?_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openComment(item.obs)}}},[_vm._v(" mdi-comment-text-multiple ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-simple-table',{staticClass:"rounded-0",attrs:{"dense":"","dark":""}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Código Styllus")]),_c('th',[_vm._v("Nome")]),_c('th',[_vm._v("Perfil")]),_c('th',[_vm._v("Data Cadastro")]),_c('th',[_vm._v("Status")])])]),_c('tbody',_vm._l((item.items),function(i){return _c('tr',{key:i.id},[_c('td',[_vm._v(_vm._s(i.revendedor.cliente.codigoStyllus))]),_c('td',[_vm._v(_vm._s(i.revendedor.cliente.nome))]),_c('td',[_vm._v(_vm._s(i.revendedor.cliente.tipo))]),_c('td',[_vm._v(_vm._s(_vm._f("date")(i.revendedor.cliente.dataCadastro)))]),_c('td',[_vm._v(_vm._s(i.revendedor.cliente.status))])])}),0)])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }