<template>
  <v-card
    outlined
  >
    <v-card-title>
      PAGAMENTOS
      <v-spacer />
      <search @applyFilters="applyFilters" />
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="pagamentos"
        :single-expand="true"
        :expanded.sync="expanded"
        item-key="id"
        show-expand
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:item.statusComissao="{ item }">
          <td>
            <v-chip
              :color="item.statusComissao == 'Pago' ? 'success' : 'warning'"
            >
              {{ item.statusComissao }}
            </v-chip>
          </td>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.totalComissaoAcerto="{ item }">
          <td>{{ item.totalComissaoAcerto | currency }}</td>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.totalComissaoConsignado="{ item }">
          <td>{{ item.totalComissaoConsignado | currency }}</td>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.totalComissaoCadastrosConsignados="{ item }">
          <td>{{ item.totalComissaoCadastrosConsignados | currency }}</td>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.totalComissaoCatalogo="{ item }">
          <td>{{ item.totalComissaoCatalogo | currency }}</td>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="showPagamento && item.pagamentoPendente"
            large
            color="primary"
            @click="openPagamento(item)"
          >
            mdi-cash-plus
          </v-icon>

          <v-icon
            v-if="item.obs"
            color="primary"
            @click="openComment(item.obs)"
          >
            mdi-comment-text-multiple
          </v-icon>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="pa-0"
          >
            <v-simple-table
              class="rounded-0"
              dense
              dark
            >
              <thead>
                <tr>
                  <th>Código Styllus</th>
                  <th>Nome</th>
                  <th>Perfil</th>
                  <th>Data Cadastro</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="i in item.items"
                  :key="i.id"
                >
                  <td>{{ i.revendedor.cliente.codigoStyllus }}</td>
                  <td>{{ i.revendedor.cliente.nome }}</td>
                  <td>{{ i.revendedor.cliente.tipo }}</td>
                  <td>{{ i.revendedor.cliente.dataCadastro | date }}</td>
                  <td>{{ i.revendedor.cliente.status }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import Search from './Search'

  export default {
    components: {
      Search,
    },

    props: {
      pagamentos: {
        type: Array,
        default: () => { return [] },
      },

      showPagamento: {
        type: Boolean,
        default: () => { return false },
      },
    },

    data: () => ({
      expanded: [],

      headers: [
        { text: 'Status', value: 'statusComissao' },
        { text: 'Total de acertos', value: 'totalDeAcertos' },
        { text: 'Total comissão acerto', value: 'totalComissaoAcerto' },
        { text: 'Total de revendedores consignado', value: 'totalDeRevendedoresConsignado' },
        { text: 'Total comissão consignado', value: 'totalComissaoConsignado' },
        { text: 'Meta de cadastros consignados', value: 'metaDeCadastrosConsignados' },
        { text: 'Total comissão cadastros consignados', value: 'totalComissaoCadastrosConsignados' },
        { text: 'Total de revendedores catálogo', value: 'totalDeRevendedoresCatalogo' },
        { text: 'Total comissão catálogo', value: 'totalComissaoCatalogo' },

        { text: 'Actions', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
    }),

    methods: {
      applyFilters (params) {
        this.$emit('applyFilters', params)
      },

      openPagamento (item) {
        this.$emit('openPagamento', item)
      },

      openComment (obs) {
        this.$swal('Styllus', obs)
      },
    },
  }
</script>
