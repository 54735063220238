<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="640px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-filter</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title
        class="text-left"
      >
        <span class="text-h5">FILTROS</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="4"
            >
              <v-text-field
                v-model="model.nome"
                label="Nome"
                @keyup.enter="applyFilters"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="4"
            >
              <v-text-field
                v-model="model.cpf"
                v-mask="['###.###.###-##']"
                label="CPF"
                @keyup.enter="applyFilters"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="4"
            >
              <v-select
                v-model="model.statusComissao"
                :items="statusComissao"
                label="Status da Comissão"
                item-text="text"
                item-value="value"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
            >
              <v-menu
                v-model="menuStartDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="model.startDate"
                    label="Data Inicial"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="model.startDate"
                  @input="menuStartDate = false"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
            >
              <v-menu
                v-model="menuEndDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="model.endDate"
                    label="Data Final"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="model.endDate"
                  @input="menuEndDate = false"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error"
          text
          @click="dialog = false"
        >
          CANCELAR
        </v-btn>
        <v-btn
          color="warning"
          text
          @click="cleanUpModel"
        >
          LIMPAR
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="applyFilters"
        >
          APLICAR
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      menuStartDate: false,
      menuEndDate: false,
      statusComissao: [
        { value: 1, text: 'Pedente' },
        { value: 2, text: 'Pago' },
      ],
      model: {
        nome: '',
        cpf: '',
        tipoComissao: null,
        statusComissao: null,
        startDate: '',
        endDate: '',
      },
    }),
    methods: {
      cleanUpModel () {
        this.model = {
          nome: '',
          cpf: '',
          tipoComissao: null,
          statusComissao: null,
          startDate: '',
          endDate: '',
        }
      },
      applyFilters () {
        const mesmoEstado = Boolean(this.model.startDate) === Boolean(this.model.endDate)
        const dataInvalida = new Date(this.model.endDate) < new Date(this.model.startDate)

        if (mesmoEstado === false || dataInvalida === true) {
          this.$toast.error('Datas preenchidas erradas!')
        } else {
          this.dialog = false
          this.$emit('applyFilters', this.model)
        }
      },
    },
  }
</script>

<style>
.v-picker.v-card {
  margin-top: 0;
  margin-bottom: 0;
}
</style>
