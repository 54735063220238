<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <h2>Olá {{ user.nome }}</h2>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="4"
            offset-md="8"
          >
            <v-btn
              color="primary"
              class="mr-4"
              to="/promotor/dashboard"
              outlined
              block
            >
              MINHA DASHBOARD
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
      >
        <datagrid-pagamentos
          :pagamentos="model.pagamentos"
          @applyFilters="loadData"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import DatagridPagamentos from '../components/DatagridPagamentos'

  export default {
    components: {
      DatagridPagamentos,
    },

    data: () => ({
      model: {
        pagamentos: [],
      },
    }),

    computed: {
      ...mapGetters(['user']),
    },

    mounted () {
      this.cleanUpModel()
    },

    methods: {
      cleanUpModel () {
        this.model.pagamentos = []

        this.loadData({})
      },

      loadData (params) {
        this.$http.get('promotor/pagamento/meus', { params })
          .then(({ data }) => {
            this.model.pagamentos = data
          })
      },
    },
  }
</script>
